const burgerMenu = {
  init() {
    const TABLET_WIDTH = 1024;
    const ACTIVE_CLASS = 'active';
    const EXPANDED_CLASS = 'expanded';
    const OPEN_MENU_CLASS = 'open-menu';

    const $win = $(window);
    const $body = $('body');

    const $btnOpen = $('.nav__opener');
    const $navHolder = $('.navs__holder');
    const $mainNav = $('.main__nav');
    const $btnDrop = $mainNav.find('button');

    const $firstMainTabItem = $($('.main__nav li a')[0]);
    const $lastMainTabItem = $($('.main__nav li:last-child button')[0]);
    const $firstSubTabItem = $($('.add__nav li a')[0]);
    const $lastSubTabItem = $($('.add__nav li:last-child button')[0]);
    const $informationListLastItem = $(
      '.add__nav .information__list li:last-child a',
    );
    const $subNavLastItem = $('.main__nav .sub__nav li:last-child a');

    const dropHolder = '.sub__nav-holder';

    if (!$btnOpen.length) return;

    function addDefaultAttributes() {
      if ($win.outerWidth() <= TABLET_WIDTH) {
        $navHolder.attr('aria-hidden', 'true');
        $(dropHolder).attr('aria-hidden', 'true');
      } else {
        $navHolder.removeAttr('aria-hidden');
        $(dropHolder).removeAttr('aria-hidden');
      }
    }

    function openMenu($element) {
      $element.attr('aria-expanded', 'true').addClass(ACTIVE_CLASS);
      $navHolder.attr('aria-hidden', 'false').addClass(ACTIVE_CLASS);
      $body.addClass(OPEN_MENU_CLASS);
    }

    function closeMenu($element) {
      $element.attr('aria-expanded', 'false').removeClass(ACTIVE_CLASS);
      $navHolder.attr('aria-hidden', 'true').removeClass(ACTIVE_CLASS);
      $body.removeClass(OPEN_MENU_CLASS);
    }

    function closeDrop($element, onAnimationEnd = () => {}) {
      const $parentElement = $element.closest('li');
      $element.attr('aria-expanded', 'false').removeClass(EXPANDED_CLASS);
      $parentElement
        .find(dropHolder)
        .attr('aria-hidden', 'true')
        .animate(
          {
            height: 0,
          },
          '200',
          () => {
            $parentElement.find(dropHolder).hide();
            $parentElement.removeClass(EXPANDED_CLASS);
            onAnimationEnd();
          },
        );
    }

    function openDrop($element) {
      const $parentElement = $element.closest('li');
      $element.attr('aria-expanded', 'true').addClass(EXPANDED_CLASS);
      $parentElement
        .find(dropHolder)
        .attr('aria-hidden', 'false')
        .show()
        .animate(
          {
            height: $parentElement.find('.sub__nav').outerHeight(),
          },
          '300',
        );
      $parentElement.addClass(EXPANDED_CLASS);
    }

    function toggleDrop($element) {
      if (!$element.hasClass(EXPANDED_CLASS)) {
        openDrop($element);
      } else {
        closeDrop($element);
      }
    }

    function expandDrop() {
      $btnDrop.on('click', function() {
        const $this = $(this);
        const $openedButton = $btnDrop.filter(`.${EXPANDED_CLASS}`);

        if ($this.hasClass(EXPANDED_CLASS)) {
          closeDrop($this);
          return;
        }

        if (!$openedButton.length) {
          toggleDrop($this);
        } else {
          closeDrop($btnDrop.filter(`.${EXPANDED_CLASS}`), () => {
            toggleDrop($this);
          });
        }
      });
    }

    function activateResizeHandler() {
      const $doc = $('html');
      const resizeClass = 'resize-active';
      let flag;
      let timer;
      const removeClassHandler = function() {
        flag = false;
        $doc.removeClass(resizeClass);
      };
      const resizeHandler = function() {
        if (!flag) {
          flag = true;
          $doc.addClass(resizeClass);
        }
        clearTimeout(timer);
        timer = setTimeout(removeClassHandler, 500);
      };
      $win.on('resize orientationchange', resizeHandler);
    }

    function clickOutside() {
      $(document).off('mouseup.resize touchstart.resize click.resize');
      $(document).on('mouseup.resize touchstart.resize click.resize', e => {
        if (
          $win.outerWidth() <= TABLET_WIDTH &&
          !$navHolder.is(e.target) &&
          $navHolder.has(e.target).length === 0 &&
          !$btnOpen.is(e.target) &&
          $btnOpen.has(e.target).length === 0
        ) {
          closeMenu($btnOpen);
        }
      });
    }

    function handleSubmenuHover() {
      const $holder = $('.main__nav > li');
      const ACTIVE_HOVER_STATE_CLASS = 'is-visible';
      const PRESERVE_HOVER_STATE_MS = 300;

      let $prevMenuItem;
      let timer;

      $holder.hover(
        function(mouseIn) {
          if ($(this).hasClass(ACTIVE_HOVER_STATE_CLASS)) {
            clearTimeout(timer);
          }
          if ($prevMenuItem && !$(this).hasClass(ACTIVE_HOVER_STATE_CLASS)) {
            $prevMenuItem.removeClass(ACTIVE_HOVER_STATE_CLASS);
            clearTimeout(timer);
          }
          $(this).addClass(ACTIVE_HOVER_STATE_CLASS);
        },
        function(mouseOut) {
          $prevMenuItem = $(this);
          timer = setTimeout(() => {
            $(this).removeClass(ACTIVE_HOVER_STATE_CLASS);
          }, PRESERVE_HOVER_STATE_MS);
        },
      );
    }

    expandDrop();
    activateResizeHandler();
    handleSubmenuHover();

    $win.on('load', clickOutside);
    $win.on('load', addDefaultAttributes);
    $win.on('resize', () => {
      clickOutside();
      addDefaultAttributes();
    });

    $btnOpen.on('click', function() {
      if (!$(this).hasClass(ACTIVE_CLASS)) {
        openMenu($(this));
      } else {
        closeMenu($(this));
      }
    });

    $btnOpen.on('keydown', e => {
      if (
        e.keyCode == 9 &&
        $btnOpen.hasClass(ACTIVE_CLASS) &&
        $win.width() < 1025
      ) {
        e.preventDefault();
        $firstMainTabItem.focus();
      }
    });

    $lastMainTabItem.on('keydown', e => {
      if (
        e.keyCode == 9 &&
        !$lastMainTabItem.hasClass(EXPANDED_CLASS) &&
        e.shiftKey === false &&
        $win.width() < 1025
      ) {
        e.preventDefault();
        $firstSubTabItem.focus();
      }
    });

    $lastSubTabItem.on('keydown', e => {
      if (
        e.keyCode == 9 &&
        !$lastSubTabItem.hasClass(EXPANDED_CLASS) &&
        $win.width() < 1025
      ) {
        e.preventDefault();
        $btnOpen.focus();
      }
    });

    $informationListLastItem.on('keydown', e => {
      if (e.keyCode == 9 && e.shiftKey === false && $win.width() < 1025) {
        e.preventDefault();
        $btnOpen.focus();
      }
    });

    $subNavLastItem.on('keydown', e => {
      if (e.keyCode == 9 && e.shiftKey === false && $win.width() < 1025) {
        e.preventDefault();
        $firstSubTabItem.focus();
      }
    });

    $firstSubTabItem.on('keydown', e => {
      if (e.keyCode == 9 && e.shiftKey === true && $win.width() < 1025) {
        e.preventDefault();
        if ($lastMainTabItem.hasClass(EXPANDED_CLASS)) {
          $subNavLastItem.focus();
        } else {
          $lastMainTabItem.focus();
        }
      }
    });

    $firstMainTabItem.on('keydown', e => {
      if (e.keyCode == 9 && e.shiftKey === true && $win.width() < 1025) {
        e.preventDefault();
        $btnOpen.focus();
      }
    });
  },
};
export default burgerMenu;
