const events = {};
events[new Date('07/04/2019')] = new Date('07/04/2019');
events[new Date('07/07/2019')] = new Date('07/07/2019');
events[new Date('07/20/2019')] = new Date('07/20/2019');
events[new Date('07/25/2019')] = new Date('07/25/2019');

const eventsCalendar = (() => {
  const init = () => {
    if (!$('#datapicker').length) {
      return;
    }
    const $calendar = $('#datapicker');
    const $doc = $(document);
    const $parentCalendar = $('.datepicker-holder');
    const $parentCategory = $('.category-calendar');
    const $lastRadio = $parentCategory.find('input[type="radio"]');
    const btnPrev = '.ui-datepicker-prev';
    const btnNext = '.ui-datepicker-next ';
    let $lastTd;
    const cellsArray = [];

    if (!$calendar.length) return;

    function highlightDaysWithEvents(date) {
      const highlight = events[date];

      if (highlight) {
        return [true, 'event-date', ''];
      }
      return [true, '', ''];
    }

    $calendar.datepicker({
      autoSize: true,
      changeMonth: false,
      changeYear: true,
      dayNamesMin: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
      monthNamesShort: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ],
      yearRange: '1950:+100',
      dateFormat: 'yy-mm-dd',
      beforeShowDay: highlightDaysWithEvents,
      onChangeMonthYear(year, month, inst) {
        setTimeout(function() {
          $(`${btnPrev},${btnNext}`).attr('href', '#');
        }, 50);
      },
    });

    setTimeout(() => {
      $(`${btnPrev},${btnNext}`).attr('href', '#');
    }, 50);

    const $mnth = $calendar.find('.ui-datepicker-month');
    const $year = $calendar.find('.ui-datepicker-year');

    $mnth.attr('id', 'ui-mnth');
    $year.attr('id', 'ui-year');
    $mnth.wrap('<label for="ui-mnth"></label>');
    $year.wrap('<label for="ui-year"></label>');

    findLastTd();
    focusLastEl($lastRadio, $parentCategory);
    focusLastEl($lastTd, $parentCalendar);

    function findLastTd() {
      const $table = $('.ui-datepicker-calendar');
      $table.find('tr:last-child td[data-event=click]').each(function() {
        cellsArray.push($(this));
      });
      $lastTd = cellsArray[cellsArray.length - 1];
    }

    $doc.on('click ', btnPrev, switchMonth);
    $doc.on('click ', btnNext, switchMonth);

    function switchMonth() {
      if ($(this).is(btnPrev)) {
        setTimeout(function() {
          $(btnPrev).focus();
        }, 50);
      }
      if ($(this).is(btnNext)) {
        setTimeout(function() {
          $(btnNext).focus();
        }, 50);
      }
      findLastTd();
      focusLastEl($lastTd, $parentCalendar);
    }

    function focusLastEl($element, $parent) {
      $element.on('keydown', function(e) {
        const code = e.keyCode ? e.keyCode : e.which;
        if (code === 9) {
          $parent.find('.category-dates-btn').focus();
          e.preventDefault();
        }
      });
    }
  };

  return {
    init,
  };
})();

export default eventsCalendar;
