const coursesTable = (() => {
  const jcf = require('jcf');

  const init = () => {
    const ajaxPath = '../js/data/courses.json';
    const $tableHolder = $('#table-courses');
    require('datatables.net-dt')();
    require('datatables.net-responsive-dt')();
    const table = $tableHolder.DataTable({
      ajax: ajaxPath,
      processing: true,
      sPaginationType: 'ellipses',
      deferLoading: 57,
      language: {
        search: 'Search Within List',
        searchPlaceholder: 'Type to filter by keyword',
      },
      columns: [{ data: 'title' }, { data: 'name' }, { data: 'subject' }],
      columnDefs: [
        {
          targets: 0,
          data: 'title',
          render(data, type, row) {
            return `<span class="course-title">${data}</span>`;
          },
        },
        {
          targets: 1,
          data: 'name',
          render(data, type, row) {
            return (
              `<h2><a class="course-link" href="${row.url}">${data}</a></h2>` +
              `<p class="description">${row.description}</p>` +
              `<strong class="units">${row.units} ${
                row.units > 1 ? 'units' : 'unit'
              }</strong>`
            );
          },
        },
        {
          targets: '_all',
          orderable: false,
        },
      ],
      dom: '<"heading-table"<"toolbar">rf><"wrapper-table"t><"footer-table">',
      keys: true,
      responsive: {
        details: {
          display: $.fn.dataTable.Responsive.display.childRowImmediate,
          renderer: $.fn.dataTable.Responsive.renderer.tableAll({
            tableClass: 'table-mobile',
          }),
        },
      },
      initComplete() {
        this.api()
          .columns()
          .every(function(i) {
            const column = this;
            const info = this.page.info();
            const toolbar = $('.toolbar');
            const holder = document.createElement('div');
            const label = document.createElement('label');
            const searchHolder = $('#table_filter');
            const select = $(
              '<select class="custom-select"><option value="">All</option></select>',
            );

            $(label).text(this.header().innerText);

            $(label)
              .attr('for', `input-${i}`)
              .appendTo(holder);
            select.attr('id', `input-${i}`).appendTo(holder);
            $(holder)
              .addClass('filter-box')
              .appendTo(toolbar);
            searchHolder.appendTo(toolbar);

            select.on('change', function() {
              const val = $.fn.dataTable.util.escapeRegex($(this).val());
              column.search(val ? `${val}$` : '', true, false).draw();
            });

            jcf.setOptions({
              wrapNative: false,
              wrapNativeOnMobile: false,
              fakeDropInBody: false,
            });

            jcf.replace(select);

            column
              .data()
              .unique()
              .sort()
              .each(function(d, j) {
                select.append(`<option value="${d}">${d}</option>`);
              });
          });
      },
    });

    const selectShowInit = () => {
      const selectShow = $('[name="table_length"]');

      jcf.setOptions({
        wrapNative: false,
        wrapNativeOnMobile: false,
        fakeDropInBody: false,
      });

      jcf.replace(selectShow);
    };

    const renderBtnClear = () => {
      const btnClear = document.createElement('button');
      const iconClear = document.createElement('em');
      const searchHolder = $('#table-courses_filter');

      $(btnClear).addClass('clear-table');
      // .text('Reset Table');
      $(iconClear)
        .addClass('icon icon-refresh')
        .attr('aria-hidden', 'true');
      $(iconClear).appendTo(btnClear);
      $(btnClear)
        .append('<span class="sr-only">reset table</span>')
        .insertAfter(searchHolder);
    };

    const resetFilter = () => {
      $('.clear-table').on('click', function(e) {
        e.preventDefault();
        $('.custom-select').prop('selectedIndex', 0);
        jcf.replace($('.custom-select'));
        table
          .search('')
          .columns()
          .search('')
          .draw();
      });
    };

    table.on('init.dt', function() {
      renderBtnClear();
      resetFilter();
      selectShowInit();
    });
  };
  return {
    init,
  };
})();

export default coursesTable;
