const filterCollapse = (() => {
  

  const init = () => {
    const EXPANDED_CLASS = 'open';

    const btnDrop = '.filter-btn-drop';
      let holder = '.mobile-filter-drop';

    function toggleSection($element) {
      if (!$element.hasClass(EXPANDED_CLASS)) {
        openSection($element);
      } else {
        closeSection($element);
      }
    }

    function openSection($element) {
      $element.addClass(EXPANDED_CLASS).attr('aria-expanded', 'true');
      $element
        .next(holder)
        .delay(200)
        .slideDown(300)
        .attr('aria-hidden', 'false');
    }

    function closeSection($element, onAnimationEnd = () => {}) {
      $element.removeClass(EXPANDED_CLASS).attr('aria-expanded', 'false');
      $element
        .next(holder)
        .slideUp(300, onAnimationEnd)
        .attr('aria-hidden', 'true');
    }

    const expandDrop = function(e) {
      const $this = $(e.currentTarget);
      const $openedButton = $(btnDrop).filter(`.${EXPANDED_CLASS}`);
      if (
        (e.which === 13 || e.type === 'click') &&
        $this.hasClass(EXPANDED_CLASS)
      ) {
        e.preventDefault();
        closeSection($this);
        return;
      }

      if ((e.which === 13 || e.type === 'click') && !$openedButton.length) {
        toggleSection($this);
      } else {
        closeSection($(btnDrop).filter(`.${EXPANDED_CLASS}`), () => {
          toggleSection($this);
        });
      }
    };

    $(btnDrop).on('click', $.throttle(1000, expandDrop));

    $(window).on('load resize orientationchange', function() {
      if ($(window).width() >= 1024) {
        $(holder).removeAttr('aria-hidden');
      }
    });
  };
  return {
    init,
  };
})();

export default filterCollapse;
