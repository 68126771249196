import { detectSlidePosition } from './helpers';

const Swiper = require('swiper');
const enquire = require('enquire.js');

const captionCarousel = (() => {
  const controlsFitToBottom = () => {
    const $controlsContainer = $('.caption-carousel__controls');
    const $currentSlideImg = $(
      '.caption-carousel__holder .swiper-slide-active .caption-carousel__img-holder',
    );
    const $newControlsOffset =
      $($currentSlideImg).outerHeight(true) -
      $($controlsContainer).outerHeight(true); // eslint-disable-line
    $($controlsContainer).css({
      top: $newControlsOffset,
    });
  };

  const init = () => {
    const captionSwiper = new Swiper('.caption-carousel__holder', {
      // eslint-disable-line
      speed: 400,
      slidesPerView: 'auto',
      centeredSlides: true,
      spaceBetween: 50,
      scrollbar: {
        el: '.swiper-scrollbar',
        hide: false,
      },
      navigation: {
        nextEl: '.caption-carousel__holder .swiper-button-next',
        prevEl: '.caption-carousel__holder .swiper-button-prev',
      },
      breakpoints: {
        699: {
          centeredSlides: false,
          spaceBetween: 17,
        },
        1024: {
          centeredSlides: false,
          spaceBetween: 30,
        },
      },
      on: {
        init() {
          controlsFitToBottom();
          setTimeout(() => {
            detectSlidePosition($(this.$el[0]));
          }, 3000);
        },
        slideChangeTransitionEnd() {
          detectSlidePosition($(this.$el[0]));
        },
        transitionEnd() {
          detectSlidePosition($(this.$el[0]));
        },
        resize() {
          detectSlidePosition($(this.$el[0]));
          controlsFitToBottom();
        },
      },
    });
  };
  return {
    init,
  };
})();

export default captionCarousel;
