const searchForm = {
  init() {
    const ACTIVE_CLASS = 'active';

    const $btnOpen = $('.search__opener');
    const $form = $('.search__panel');

    if (!$form.length) return;

    function openDrop($element) {
      $element.attr('aria-expanded', 'true').addClass(ACTIVE_CLASS);
      $form
        .attr('aria-hidden', 'false')
        .delay(200)
        .slideDown(300);
      setTimeout(() => {
        $form.find('input').focus();
      }, 200);
    }

    function closeDrop($element) {
      $element.attr('aria-expanded', 'false').removeClass(ACTIVE_CLASS);
      $form.attr('aria-hidden', 'true').slideUp(300);
    }

    function toggleDrop($element) {
      if (!$element.hasClass(ACTIVE_CLASS)) {
        openDrop($element);
      } else {
        closeDrop($element);
      }
    }

    $btnOpen.on('click', function() {
      const $this = $(this);
      toggleDrop($this);
    });

    $('.search__form button').on('keydown', e => {
      const code = e.keyCode ? e.keyCode : e.which;
      if (e.shiftKey && Number(code) === 9) return;
      if (code === 9) {
        closeDrop($btnOpen);
        e.preventDefault();
      }
    });

    $(document).on('touchstart click', e => {
      if (
        !$form.is(e.target) &&
        $form.has(e.target).length === 0 &&
        !$btnOpen.is(e.target) &&
        $btnOpen.has(e.target).length === 0
      ) {
        closeDrop($btnOpen);
      }
    });
  },
};
export default searchForm;
