import Instafeed from 'instafeed.js';

const instagramLoader = (() => {
  const feed = new Instafeed({
    get: 'user',
    userId: '264038650',
    clientId: 'afa9ae06a05f41118b653e727b5fbcae',
    accessToken: '264038650.afa9ae0.4e22a8a3b222465db97eab88ab4ebed6',
    sortBy: 'most-recent',
    limit: 3,
    template:
      '<div class="instagram__item"><a class="instagram__link" href="{{link}}" target="_blank" style="background-image: url({{image}})"><span class="accessibility">{{link}}</a></div>',
    resolution: 'standard_resolution',
  });

  const init = () => {
    if (!$('#instafeed').length) {
      return;
    }
    feed.run();
  };

  return {
    init,
  };
})();

export default instagramLoader;
