const cookieMessage = {
  init: function() {
    require('../vendor/jquery.cookie');
    const $header = $('.header');
    const $window = $(window);

    let messageElements = '.cookie-message';
    let $alert = $('.alert-banner');

    $(window).on('load resize', function() {
      calcAlertHeight();
    });

    function calcAlertHeight() {
      let windowHeight = $window.outerHeight();
      let alertHeight = 0;
      if ($alert.is(':visible')) {
        alertHeight = $alert.outerHeight();
      }
      let menuHeight = windowHeight - alertHeight - 50;
      $('.navs__holder').css('height', menuHeight);
      $header.css('top', alertHeight);
    }

    $(messageElements).each(function() {
      let $this = $(this);
      let cookieName = $this.data('cookie-name'),
        cookieExpire = $this.data('cookie-expire');
      let alertHeight = $this.outerHeight();
      if (!$.cookie(cookieName)) {
        closeMessage($this, cookieName, cookieExpire);
        $this.show();
        calcAlertHeight();
        setTimeout(function() {
          $header.css('top', alertHeight);
        }, 100);
      }
    });

    function closeMessage($this, cookieName, cookieExpire) {
      $this.find('.my-close-button').on('click', function() {
        let expDate = new Date();
        expDate.setTime(
          expDate.getTime() + cookieExpire * 365 * 24 * 60 * 60 * 1000,
        );
        $.cookie(cookieName, 1, {
          path: '/',
          expires: expDate,
        });
        $(document).trigger('close-alert');
        $(this)
          .closest(messageElements)
          .slideUp(300);
        $header.animate(
          {
            top: 0,
          },
          300,
        );
        setTimeout(function() {
          calcAlertHeight();
        }, 300);
        $(document).trigger('closeCookieBanner');
      });
    }
  },
};

export default cookieMessage;
