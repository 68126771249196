const axios = require('axios');

const SORTY_TYPE_PROGRAM = 'Department';
const SORTY_TYPE_ALPHABET = 'A-Z';
const SORT_TYPES = [SORTY_TYPE_PROGRAM, SORTY_TYPE_ALPHABET];

const Filter = {
  template: `<div class="filter-list">

      <div v-for="(section, index) in sections" :key="section.label" :class="['holder', section.type + '-section-holder']">
        <fieldset class="group" role="radiogroup" :aria-labelledby="'group_label_'+index" :id="'rg'+index">
          <legend :id="'group_label_'+index">{{section.label}}</legend>
          <div class="filter-wrap">
          <strong class="filter-title">{{section.label}}</strong>

            <div class="control-wrap">
              <div v-for="(bunches, i) in section.optionBunches" :key="i" class="filter-option-wrapper">
                <div v-for="option in bunches" :key="option.id" :class="section.type + '-holder'">
                  <label :for="option.value || section.field + '-all'" class="control-label">
                    <input role="radio" :type="section.type" v-model="filter[section.field]" :value="option.value" @change="onChange(section.field, $event.target.value, $event.target.checked)"
                      :id="option.value || section.field + '-all'" :name="'radio-type-'+index"/>
                    <span class="control-text">{{option.label}}</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </fieldset>
      </div>


    </div>`,
  props: {
    value: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    fields: {
      type: Array,
      default: [
        {
          field: 'type',
          label: 'Show me',
          multiple: false,
          selectAll: true,
          selectAllTitle: 'All Program Types',
        },
        {
          field: 'program',
          label: 'From',
          multiple: false,
          selectAll: true,
          selectAllTitle: 'All Pathways',
        },
        {
          field: 'locate',
          label: 'Located At',
          multiple: false,
          selectAll: true,
          selectAllTitle: 'Any Location or Online',
        },
      ],
    },
    bunchCoef: {
      type: Number,
      default: 0.5,
    },
  },
  data() {
    return {
      filter: {},
    };
  },
  watch: {
    fields: {
      handler(fields) {
        const filter = {};

        fields.forEach(fieldConfig => {
          filter[fieldConfig.field] = fieldConfig.multiple ? [''] : '';
        });

        this.filter = filter;
      },
      immediate: true,
    },
    filter: {
      handler(filter) {
        let items = [...this.items];

        for (const filterKey in filter) {
          const filterValue = filter[filterKey];

          if (typeof filterValue === 'object') {
            const showAll = filterValue.indexOf('') !== -1;

            items = showAll
              ? items
              : items.filter(
                  item => filterValue.indexOf(item[filterKey]) !== -1,
                ); // jshint ignore:line
          } else {
            const showAll = !filterValue;

            items = showAll
              ? items
              : items.filter(item => {
                  if (Array.isArray(item[filterKey])) {
                    return (
                      item[filterKey].filter(
                        nestedItem => nestedItem === filterValue,
                      ).length > 0
                    );
                  }
                  return item[filterKey] === filterValue;
                }); // jshint ignore:line
          }
        }

        this.$emit('input', items);
      },
      deep: true,
    },
  },
  computed: {
    sections() {
      return this.fields.map(fieldConfig => {
        const options = [
          ...(fieldConfig.selectAll
            ? [
                {
                  label: fieldConfig.selectAllTitle,
                  value: '',
                },
              ]
            : []),
          ...this.getFieldOptions(fieldConfig.field),
        ];

        return {
          label: fieldConfig.label,
          field: fieldConfig.field,
          selectAllTitle: fieldConfig.selectAllTitle,
          options,
          optionBunches: this.getOptionBunches(options),
          type: fieldConfig.multiple ? 'checkbox' : 'radio',
        };
      });
    },
  },
  methods: {
    getFieldOptions(field) {
      const options = [];

      this.items.forEach(item => {
        const hasOption = options.find(option => option.value === item[field]);

        if (!hasOption) {
          if (Array.isArray(item[field])) {
            item[field].forEach(option => {
              const hasNestedOption = options.find(
                optionValue => optionValue.value === option,
              );

              if (!hasNestedOption) {
                options.push({
                  label: option,
                  value: option,
                });
              }
            });

            return;
          }
          options.push({
            label: item[field],
            value: item[field],
          });
        }
      });

      return options;
    },
    onChange(field, value, checked) {
      const isSingularFilter = typeof this.filter[field] !== 'object';

      if (isSingularFilter) {
        return;
      }

      const isShowAllSelected = !value && checked;
      const isFilterItemSelected = value && checked;

      if (isShowAllSelected) {
        this.filter[field] = [''];
      }

      if (isFilterItemSelected) {
        this.filter[field] = this.filter[field].filter(selectedValue =>
          Boolean(selectedValue),
        );
      }
    },
    getOptionBunches(options) {
      const bunches = [];
      const bunchSize = options.length * this.bunchCoef;

      for (let i = 0, j = options.length; i < j; i += bunchSize) {
        bunches.push(options.slice(i, i + bunchSize));
      }
      return bunches;
    },
  },
};

const Sorting = {
  template: `
    <select
      v-model="value"
      @change="$emit('input', value)"
    >
      <option
        v-for="(type, i) in sortTypes"
        :key="type"
        :selected="i === 0"
        :value="type"
      >
        {{type}}
      </option>
    </select>
    `,
  props: {
    value: {
      type: String,
      default: SORTY_TYPE_PROGRAM,
    },
    sortTypes: {
      type: Array,
      default: SORT_TYPES,
    },
  },
};

const Items = {
  template: `
    <div class="filter-items">
      <div
        v-for="bunch in bunches"
        :key="bunch.label"
        class="row"
      >
        <div class="title-holder">
          <h2 class="h3">
              {{bunch.label}}
          </h2>
        </div>
        <ul class="filter-result-list">
          <li
            v-for="item in bunch.items"
          >
            <h3 class="h5">
              <a :href="item.url" target="_blank">{{item.title}}<em class="icon icon-arrow-right" aria-hidden="true"></a>
            </h3>
          </li>
        </ul>
      </div>
    </div>
    `,
  props: {
    items: {
      type: Array,
      required: true,
    },
    sorting: {
      type: String,
    },
  },
  computed: {
    bunches() {
      switch (this.sorting) {
        case SORTY_TYPE_ALPHABET:
          return this.getAlphabetBunches();
        case SORTY_TYPE_PROGRAM:
          return this.getFieldBunches('program');
        default:
          return [];
      }
    },
  },
  methods: {
    getAlphabetBunches() {
      return this.getBunches(item => item.title[0].toLowerCase());
    },
    getFieldBunches(field) {
      return this.getBunches(item => item[field]).map(bunch => {
        return {
          label: bunch.label,
          items: bunch.items,
          url: bunch.items[0][`${field}Url`],
        };
      });
    },
    getBunches(getItemBunch) {
      const bunches = {};

      this.items.forEach(item => {
        const bunchName = getItemBunch(item);

        if (!bunches[bunchName]) {
          bunches[bunchName] = [];
        }

        bunches[bunchName].push(item);
      });

      return Object.entries(bunches)
        .map(([name, items]) => ({
          label: name,
          items: items.sort((itemA, itemB) => {
            return itemA.title.localeCompare(itemB.title);
          }),
        }))
        .sort((bunchA, bunchB) => {
          return bunchA.label.localeCompare(bunchB.label);
        });
    },
  },
};

const Loader = {
  template: `
    <div class="loader">
      <span class="spinner"></span>
    </div>
  `,
};

export default {
  init() {
    if (!document.getElementById('filter-app')) {
      return;
    }

    const vm = new Vue({
      el: '#filter-app',
      template: '#filter-app-template',
      data() {
        return {
          items: [],
          filteredItems: [],
          sorting: SORTY_TYPE_PROGRAM,
          loading: false,
          url: '',
          isFilterChanged: false,
          isInitialized: false,
        };
      },
      components: {
        filterer: Filter,
        sorter: Sorting,
        loader: Loader,
        items: Items,
      },
      beforeMount() {
        this.url = this.$el.dataset.url;
      },
      mounted() {
        this.loadItems();
      },
      watch: {
        items: {
          handler(items) {
            this.filteredItems = items;
          },
        },
        filteredItems: {
          handler() {
            if (this.isInitialized) {
              this.isFilterChanged = true;
            }

            this.isInitialized = true;
          },
        },
      },
      methods: {
        loadItems() {
          this.loading = true;

          axios
            .get(this.url)
            .then(response => {
              this.items = response.data;
            })
            .catch(error => {
              console.error(error);
            })
            .finally(() => (this.loading = false));
        },
      },
    });
  },
};
