const stickyHeader = (() => {
  const init = () => {
    let lastScrollTop = 0;

    const $alert = $('.alert-banner');

    function scrollHeader() {
      const $holder = $('.header');
      let headerHeight = $holder.outerHeight();
      const $sectionNav = $('.section__nav');
      const alertHeight = $alert.outerHeight();
      const classDown = 'scrolling-down';
      const classUp = 'scrolling-up';
      const classAnimate = 'animate-header';
      const classFixed = 'js-fixed';
      const st = $(window).scrollTop();
      const breadcrumbTop = $('#main').offset().top;

      if ($alert.length && $alert.is(':visible')) {
        headerHeight += alertHeight;
      } else {
        headerHeight = headerHeight; // eslint-disable-line
      }

      if ($sectionNav.length) {
        if (st > breadcrumbTop) {
          $sectionNav.addClass(classFixed);
        }
        if (st <= breadcrumbTop - headerHeight) {
          $sectionNav.removeClass(classFixed);
        }
      }

      if (st > lastScrollTop && lastScrollTop >= headerHeight) {
        setTimeout(() => {
          $holder.addClass(classAnimate);
        }, 0);
        $holder.addClass(classDown);
        $holder.removeClass(classUp);
        $sectionNav.removeClass(classUp);
      }
      if (
        $(document).scrollTop() > $(window).outerHeight() &&
        st > lastScrollTop
      ) {
        setTimeout(() => {
          $holder.addClass(classAnimate);
        }, 0);
        $holder.addClass(classDown);
      }
      if (st < lastScrollTop && lastScrollTop > headerHeight) {
        $holder.addClass(classUp);
        $sectionNav.addClass(classUp);
        $holder.removeClass(classDown);
      }
      lastScrollTop = st;

      if ($alert.length && $alert.is(':visible')) {
        if (st <= alertHeight) {
          $holder.removeClass(classAnimate);
          setTimeout(() => {
            $holder.removeClass(classAnimate);
          }, 0);
          $holder.removeClass(classDown);
          $holder.removeClass(classUp);
          $sectionNav.removeClass(classUp);
        }
      }

      if (st <= breadcrumbTop - headerHeight) {
        $holder.removeClass(classAnimate);
        setTimeout(() => {
          $holder.removeClass(classAnimate);
        }, 0);
        $holder.removeClass(classDown);
        $holder.removeClass(classUp);
        $sectionNav.removeClass(classUp);
      }

      if ($alert.is(':visible') && st <= alertHeight + 34) {
        $sectionNav.removeClass(classFixed);
      }
    }

    $(window).on('load scroll', () => {
      scrollHeader();
    });
  };
  return {
    init,
  };
})();

export default stickyHeader;
