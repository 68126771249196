import { detectSlidePosition } from './helpers';

const Swiper = require('swiper');
const enquire = require('enquire.js');

const instagramSlider = (() => {
  const build = () => {
    let mySwiper;
    if ($('.carousel__holder').length) {
      enquire.register('screen and (max-width:1349px)', {
        // eslint-disable-line
        match: () => {
          mySwiper = new Swiper('.carousel__holder', {
            // eslint-disable-line
            speed: 400,
            slidesPerView: 'auto',
            spaceBetween: 50,
            followFinger: false,
            scrollbar: {
              el: '.swiper-scrollbar',
              hide: false,
            },
            navigation: {
              nextEl: '.carousel__holder .swiper-button-next',
              prevEl: '.carousel__holder .swiper-button-prev',
            },
            breakpoints: {
              699: {
                spaceBetween: 14,
              },
              1349: {
                spaceBetween: 30,
              },
            },
            on: {
              init() {
                setTimeout(() => {
                  detectSlidePosition($(this.$el[0]));
                }, 3000);
              },
              slideChangeTransitionEnd() {
                detectSlidePosition($(this.$el[0]));
              },
              transitionEnd() {
                detectSlidePosition($(this.$el[0]));
              },
              resize() {
                detectSlidePosition($(this.$el[0]));
              },
            },
          });
        },
      });
    }
  };

  const init = () => {
    build();
  };

  return {
    init,
  };
})();

export default instagramSlider;
