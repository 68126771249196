export function handleVh() {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
}

export function detectSlidePosition($parent) {
  if ($parent.length) {
    const $elm = $parent.find('.swiper-slide');
    $elm.each(function() {
      const $this = $(this);
      const off = $this.offset();
      const l = off.left;
      const w = $this.width();
      const docW = $parent.width();

      const isEntirelyVisible = l + w <= docW;

      if (!isEntirelyVisible || l < 0) {
        $this.find('a').attr('tabindex', '-1');
      } else {
        $this.find('a').removeAttr('tabindex');
      }
    });
  }
}
