const scrollToTop = {
  init() {
    const $btnTop = $('.back-to-top');
    const $elementFocus = $('.header__logo');

    if (!$btnTop.length) return;

    function scrollTop() {
      $btnTop.click(() => {
        $('html, body').animate(
          {
            scrollTop: 0,
          },
          800,
        );
        setTimeout(() => {
          $elementFocus.focus();
        }, 800);
        return false;
      });
    }

    scrollTop();
  },
};

export default scrollToTop;
