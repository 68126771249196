const infoDrop = {
  init() {
    const TABLET_WIDTH = 1024;
    const EXPANDED_CLASS = 'expanded';

    const $win = $(window);

    const $addNav = $('.add__nav');
    const $btnDrop = $addNav.find('button');

    const dropHolder = '.drop__holder';

    function addDefaultAttributes() {
      if ($win.outerWidth() <= TABLET_WIDTH) {
        $(dropHolder).attr('aria-hidden', 'true');
      } else {
        $(dropHolder).removeAttr('aria-hidden');
      }
    }

    function openDrop($element) {
      const $parentElement = $element.closest('li');
      $element.attr('aria-expanded', 'true').addClass(EXPANDED_CLASS);
      $parentElement
        .find(dropHolder)
        .attr('aria-hidden', 'false')
        .show()
        .animate(
          {
            height: $parentElement.find('.information__list').outerHeight(),
          },
          'slow',
        );
      $parentElement.addClass(EXPANDED_CLASS);
    }

    function closeDrop($element, onAnimationEnd = () => {}) {
      const $parentElement = $element.closest('li');
      $element.attr('aria-expanded', 'false').removeClass(EXPANDED_CLASS);
      $parentElement
        .find(dropHolder)
        .attr('aria-hidden', 'true')
        .animate(
          {
            height: 0,
          },
          'slow',
          () => {
            $parentElement.find(dropHolder).hide();
            $parentElement.removeClass(EXPANDED_CLASS);
            onAnimationEnd();
          },
        );
    }

    function toggleDrop($element) {
      if (!$element.hasClass(EXPANDED_CLASS)) {
        openDrop($element);
      } else {
        closeDrop($element);
      }
    }

    function expandDrop() {
      $btnDrop.off('click.resize');
      $btnDrop.on('click.resize', function() {
        if ($win.outerWidth() > TABLET_WIDTH) return;
        const $this = $(this);
        const $openedButton = $btnDrop.filter(`.${EXPANDED_CLASS}`);

        if ($this.hasClass(EXPANDED_CLASS)) {
          closeDrop($this);
          return;
        }

        if (!$openedButton.length) {
          toggleDrop($this);
        } else {
          closeDrop($btnDrop.filter(`.${EXPANDED_CLASS}`), () => {
            toggleDrop($this);
          });
        }
      });
    }

    if (!$addNav.length) return;

    $win.on('load', expandDrop);
    $win.on('load', addDefaultAttributes);
    $win.on('resize', () => {
      expandDrop();
      addDefaultAttributes();
    });
  },
};
export default infoDrop;
