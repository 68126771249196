const $ = require('jquery');

const heroVideo = (() => {
  const $videoBox = $('.main-hero-video-holder .main-hero-bg-video');
  const $videoControlLayer = $('.hero-video-control');

  function init() {
    if (!$videoBox.length && !$videoControlLayer.length) {
      return;
    }
    $videoControlLayer.click(() => {
      if (!$videoControlLayer.hasClass('is-paused')) {
        $videoBox[0].pause();
        $videoControlLayer.toggleClass('is-paused');
      } else {
        $videoBox[0].play();
        $videoControlLayer.toggleClass('is-paused');
      }
    });
  }

  return {
    init,
  };
})();

export default heroVideo;
