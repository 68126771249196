const tabNav = (function ($) { // eslint-disable-line
  function init() {
    // "tab" key handling
    function initTabNav() {
      jQuery('.main__nav').tabNav({
        items: 'li',
      });
      jQuery('.add__nav').tabNav({
        items: 'li',
        opener: '>a, >button',
      });
      jQuery('.application-paths').tabNav({
        items: 'li',
        opener: '>a',
      });
    }
    /*
     * Accessible TAB navigation
     */

    (function ($) { // eslint-disable-line
      const isWindowsPhone = /Windows Phone/.test(navigator.userAgent);
      const isTouchDevice = (('ontouchstart' in window) || window.DocumentTouch) && "ontouchstart" in document.documentElement; // eslint-disable-line

      $.fn.tabNav = function (opt) { // eslint-disable-line
        const options = $.extend(
          {
            hoverClass: 'tab-focus',
            items: 'li',
            opener: '>a',
            delay: 10,
          },
          opt,
        );

        if (isWindowsPhone || isTouchDevice) {
          return this;
        }

        return this.each(function() {
          const nav = $(this);
          const items = nav.find(options.items);

          items.each(function(index, navItem) {
            const item = $(this);
            let navActive;
            let touchNavActive;
            const link = item.find(options.opener);
            let timer;

            const initSimpleNav = function() {
              if (!initSimpleNav.done) {
                initSimpleNav.done = true;
                item.hover(
                  () => {
                    clearTimeout(timer);
                    timer = setTimeout(() => {
                      item.addClass(options.hoverClass);
                    }, options.delay);
                  },
                  () => {
                    clearTimeout(timer);
                    timer = setTimeout(() => {
                      item.removeClass(options.hoverClass);
                    }, options.delay);
                  },
                );
              }
            };

            link
              .bind('focus', () => {
                navActive = nav.hasClass('js-nav-active');
              touchNavActive = window.TouchNav && TouchNav.isActiveOn(navItem); // eslint-disable-line
                if (!navActive || touchNavActive) {
                  initSimpleNav();
                }
                item.trigger(
                  navActive && touchNavActive ? 'itemhover' : 'mouseenter',
                );
              })
              .bind('blur', () => {
                item.trigger(
                  navActive && touchNavActive ? 'itemleave' : 'mouseleave',
                );
              });
          });
        });
      };
    })(jQuery);

    // page init
    jQuery(() => {
      initTabNav();
    });
  }

  return {
    init,
  };
})(window.jQuery);

export default tabNav;
