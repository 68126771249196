import '@babel/polyfill';

import uiDatepicker from 'jquery-datepicker';
import { handleVh } from './object/helpers';
import accordion from './object/accordion';
import inlineVideo from './object/video';
import column from './object/column';
import instagramLoader from './object/instafeed';
import twitterLoader from './object/twitter';
import menu from './object/menu';
import navTab from './object/navTab';
import navDropdown from './object/navDropdown';
import stickyHeader from './object/stickyHeader';
import openClose from './object/openClose';
import search from './object/searchForm';
import genericSlider from './object/genericSlider';
import gen3colSlider from './object/gen3colSlider';
import scrollToTop from './object/scrollToTop';
import forms from './object/forms';
import alerts from './object/alert';
import animations from './object/animations';
import eventsCalendar from './object/eventsCalendar';
import filters from './object/filters';
import filtersCollapse from './object/filtersCollapse';
import newsSlider from './object/newsSlider';
import directoryTable from './object/directoryTable';
import coursesTable from './object/coursesTable';
import coursesDegreesTable from './object/coursesDegreesTable';
import bgVideo from './object/bgVideo';
import detectIE from './object/detectIE';

window.$ = require('jquery');
window.jQuery = require('jquery');
const objectFitImages = require('object-fit-images');

uiDatepicker(window.$);

$(() => {
  const images = $('.bg-image');
  objectFitImages(images);
  detectIE.init();
  accordion.init();
  inlineVideo.init();
  instagramLoader.init();
  twitterLoader.init();
  stickyHeader.init();
  menu.init();
  navTab.init();
  navDropdown.init();
  openClose.init({
    btnOpen: '.section__nav-btn',
    dropdown: '.section__nav__drop',
    sectionTarget: '.section__nav',
  });
  openClose.init({
    btnOpen: '.category-dates-btn',
    dropdown: '.category-dates__drop',
    sectionTarget: '.category-dates',
  });
  search.init();
  genericSlider.init();
  scrollToTop.init();
  alerts.init();
  eventsCalendar.init();
  filters.init();
  filtersCollapse.init();
  animations.init();
  newsSlider.init();
  directoryTable.init();
  coursesTable.init();
  coursesDegreesTable.init();
  bgVideo.init();

  // Run after instagram and others have loaded
  $(window).on('load resize', () => {
    setTimeout(() => {
      handleVh();
      column.init();
      gen3colSlider.init();
      forms.init();
    }, 200);
  });
});
