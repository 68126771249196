const jcf = require('jcf');

const customForm = (() => {
  const init = () => {
    const customSelect = $('.custom-select');

    if (!$(customSelect).length) {
      return;
    }

    jcf.replace(customSelect);
  };
  return {
    init,
  };
})();

export default customForm;
