const Swiper = require('swiper');
const enquire = require('enquire.js');
const UPDATE_TIMEOUT = 200;
let goToSlideTimer;

const newsSlider = (() => {
  const build = () => { 
    let mySwiper;
    if ($('.news-swiper__container').length) {
      enquire.register('screen and (min-width:1350)', {
        // eslint-disable-line
        match: () => {
          if (mySwiper) {
            mySwiper.destroy(false, true);
            mySwiper = undefined;
          }
        },
      });
      enquire.register('screen and (min-width:700px) and (max-width:1349px)', {
        // eslint-disable-line
        match: () => {
          mySwiper = new Swiper('.news-swiper__container', {
            // eslint-disable-line
            slidesPerView: 'auto',
            followFinger: false,
            spaceBetween: 0,
            on: {
              resize() {
                clearTimeout(goToSlideTimer);
                goToSlideTimer = setTimeout(() => {
                  this.slideTo(0);
                }, UPDATE_TIMEOUT);
              },
            },
          });
        },
      });
      enquire.register('screen and (max-width:699px)', {
        // eslint-disable-line
        match: () => {
          if (mySwiper) {
            mySwiper.destroy(false, true);
            mySwiper = undefined;
          }
        },
      });
    }
  };

  const init = () => {
    build();
  };

  return {
    init,
  };
})();

export default newsSlider;
