const directoryTable = (() => {
  const jcf = require('jcf');

  const init = () => {
    const ajaxPath = '../js/data/table.json';
    const $tableHolder = $('#table');
    require('datatables.net-dt')();
    require('datatables.net-buttons')();

    const table = $tableHolder.DataTable({
      ajax: ajaxPath,
      processing: true,
      pagingType: 'simple_numbers',
      sPaginationType: 'ellipses',
      deferLoading: 57,
      language: {
        search: 'Search Within Table',
        searchPlaceholder: 'Keyword',
        info: '_START_-_END_ of _TOTAL_',
        sLengthMenu:
          '<select name="table_length">' +
          '<option value="10">Show 10 per page</option>' +
          '<option value="20">Show 20 per page</option>' +
          '<option value="30">Show 30 per page</option>' +
          '<option value="40">Show 40 per page</option>' +
          '<option value="50">Show 50 per page</option>' +
          '<option value="-1">All</option>' +
          '</select>',
      },
      columns: [
        { data: 'status' },
        { data: 'program' },
        { data: 'email' },
        { data: 'phone' },
        { data: 'location' },
      ],
      columnDefs: [
        {
          targets: 0,
          data: 'status',
          render(data, type, row) {
            if (row.url) {
              return (
                `<a class="link-name" href="${row.url}">${row.name}` +
                `<em class="icon icon-right" aria-hidden="true"></em>` +
                `</a>` +
                `<span class="type">${data}</span>`
              );
            }
            return (
              `<span class="link-name" >${row.name}</span>` +
              `<span class="type ">${data}</span>`
            );
          },
        },
        {
          targets: 1,
          data: 'program',
          render(data, type, row) {
            return `<a class="program" href="${row.email}">${data}</a>`;
          },
        },
        {
          targets: 2,
          data: 'email',
          render(data, type, row) {
            return `<a class="email" href="${row.email}">${data}</a>`;
          },
        },
        {
          targets: 3,
          data: 'phone',
          render(data, type, row) {
            return `<a class="tel" href="tel:${row.phone}">${data}</a>`;
          },
        },
        {
          targets: '_all',
          orderable: false,
        },
      ],
      dom:
        '<"heading-table"B<"heading-table-filter"<"mobile-filter-drop"<"toolbar">rf><"header-controls"li>>><"wrapper-table"t><"footer-table"<"control-left"li>p>',
      buttons: [
        {
          text: 'FILTERS',
          className: 'filter-btn-drop',
          action: () => {
            const $filterHolder = $('.heading-table .heading-table-filter');
            const $filterToggle = $('.heading-table .filter-btn-drop');

            $filterToggle.toggleClass('expanded');
            $filterHolder
              .stop()
              .slideToggle(300)
              .attr(
                'aria-hidden',
                $filterHolder.attr('aria-hidden') == 'false' ? 'true' : 'false',
              );
          },
        },
      ],
      keys: true,
      initComplete() {
        this.api()
          .columns()
          .every(function(i) {
            const column = this;
            const info = this.page.info();
            const toolbar = $('.toolbar');
            const holder = document.createElement('div');
            const label = document.createElement('label');
            const searchHolder = $('#table_filter');
            const select = $(
              '<select class="custom-select"><option value="">All</option></select>',
            );

            $(label).text(this.header().innerText);

            $(label)
              .attr('for', `input-${i}`)
              .appendTo(holder);
            select.attr('id', `input-${i}`).appendTo(holder);
            $(holder)
              .addClass('filter-box')
              .appendTo(toolbar);
            searchHolder.appendTo(toolbar);

            select.on('change', function() {
              const val = $.fn.dataTable.util.escapeRegex($(this).val());
              column.search(val ? `${val}$` : '', true, false).draw();
            });

            jcf.setOptions({
              wrapNative: false,
              wrapNativeOnMobile: false,
              fakeDropInBody: false,
            });

            jcf.replace(select);

            column
              .data()
              .unique()
              .sort()
              .each(function(d, j) {
                select.append(`<option value="${d}">${d}</option>`);
              });
          });
      },
    });

    const selectShowInit = () => {
      const selectShow = $('[name="table_length"]');

      jcf.setOptions({
        wrapNative: false,
        wrapNativeOnMobile: false,
        fakeDropInBody: false,
      });

      jcf.replace(selectShow);
    };

    const renderBtnClear = () => {
      const btnClear = document.createElement('button');
      const iconClear = document.createElement('em');
      const searchHolder = $('#table_filter');

      $(btnClear).addClass('clear-table');
      $(iconClear)
        .addClass('icon icon-refresh')
        .attr('aria-hidden', 'true');
      $(iconClear).appendTo(btnClear);
      $(btnClear)
        .append('<span class="sr-only">reset table</span>')
        .insertAfter(searchHolder);
    };

    const resetFilter = () => {
      $('.clear-table').on('click', function(e) {
        e.preventDefault();
        $('.custom-select').prop('selectedIndex', 0);
        jcf.replace($('.custom-select'));
        table
          .search('')
          .columns()
          .search('')
          .draw();
      });
    };

    table.on('init.dt', function() {
      renderBtnClear();
      resetFilter();
      selectShowInit();
    });
  };
  return {
    init,
  };
})();

export default directoryTable;
