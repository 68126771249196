import { WOW } from 'wowjs';

const animations = (() => {

  const init = () => {
    function afterReveal(el) {
      el.addEventListener('animationend', function (event) {
        if ($(this).hasClass('animated')) {
          $(this).find('.circle-svg').addClass('finish-animate');
        }
      });
    }

    new WOW().init();

    new WOW({
      boxClass: 'wow-circle',
      callback: afterReveal
    }).init();

  };

  return {
    init: init
  };
})();

export default animations;