/**
 * Statistic 3up display object interactions and accessiblity
 * @param {*} $
 * @returns {init} Determine and run if accordion present
 */

import 'slick-carousel';

const column = (() => {
  const instagramSettings = {
    dots: false,
    arrows: false,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    mobileFirst: true,
    variableWidth: true,
    draggable: true,
    swipe: true,
    responsive: [
      {
        breakpoint: 699,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 1200,
        settings: 'unslick',
      },
    ],
  };

  const twitterSettings = {
    dots: false,
    arrows: false,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    mobileFirst: true,
    variableWidth: true,
    draggable: true,
    swipe: true,
    responsive: [
      {
        breakpoint: 0,
        settings: 'unslick',
      },
      {
        breakpoint: 699,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 890,
        settings: 'unslick',
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 1200,
        settings: 'unslick',
      },
    ],
  };

  const threeColArrowSettings = {
    dots: false,
    arrows: true,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    mobileFirst: true,
    draggable: false,
    swipe: true,
    responsive: [
      {
        breakpoint: 0,
        settings: 'unslick',
      },
      {
        breakpoint: 699,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 1025,
        settings: 'unslick',
      },
    ],
  };

  function slickInit() {
    $('.column--three.slick--arrow:not(.slick-initialized)').slick(
      threeColArrowSettings,
    );

    $('.instagram__feed').slick(instagramSettings);
    $('.twitter__feed:not(.slick-initialized)').slick(twitterSettings);
  }

  const init = () => {
    if (!$('.column.slick').length) {
      return;
    }

    if ($(window).width() <= 1349) {
      slickInit();
    }
  };

  return {
    init,
  };
})();

export default column;
