const detectIE = {
  init() {
    if (
      navigator.userAgent.indexOf('Trident') != -1 &&
      navigator.userAgent.indexOf('MSIE') == -1
    ) {
      $('body').addClass('ie-browser');
    }
    if (
      (navigator.userAgent.indexOf('Trident') != -1 &&
        navigator.userAgent.indexOf('MSIE') == -1) ||
      navigator.userAgent.indexOf('Edge') !== -1
    ) {
      $('body').addClass('ms-browser');
    }
  },
};
export default detectIE;
