const $ = require('jquery');

const openClose = (() => {
  function init(arg) {
    const EXPANDED_CLASS = 'expanded';
    const $body = $('body');
    const $win = $(window);

    const $lastElement = $('.last-child');
    const $header = $('.header');
    const $sectionNav = $('.section__nav');
    const $alert = $('.alert-banner');

    let st;
    let headerHeight;
    let alertHeight = $alert.outerHeight();

    function recalculateHeaderHeight() {
      headerHeight = 0;
      headerHeight = $header.outerHeight();
      const ofssetMain = $('#main').offset().top;
      if ($alert.length && $alert.is(':visible')) {
        alertHeight = $alert.outerHeight();
        headerHeight = ofssetMain;
      } else {
        alertHeight = 0;
        headerHeight = headerHeight; // eslint-disable-line
      }
    }
    recalculateHeaderHeight();

    $(document).on('close-alert', () => {
      alertHeight = 0;
    });

    $win.on('resize scroll', () => {
      recalculateHeaderHeight();
    });

    if (!jQuery(arg.btnOpen).length) return;

    function openSection($element) {
      $element.addClass(EXPANDED_CLASS).attr('aria-expanded', 'true');
      $element
        .next(arg.dropdown)
        .delay(200)
        .slideDown(300)
        .attr('aria-hidden', 'false');
      if ($element.closest('.section__nav').length) {
        st = $win.scrollTop();
        $body.addClass('open-nav').css({
          '-webkit-transform': `translateY(-${st}px)`,
          transform: `translateY(-${st}px)`,
        });
        $sectionNav.css({
          '-webkit-transform': `translateY(${st - headerHeight}px)`,
          transform: `translateY(${st - headerHeight}px)`,
        });

        if (st < headerHeight) {
          $sectionNav.css({
            '-webkit-transform': '',
            transform: '',
          });
        }
        if (st > headerHeight && st <= headerHeight + 60) {
          $sectionNav.css({
            '-webkit-transform': `translateY(${st - headerHeight}px)`,
            transform: `translateY(${st - headerHeight}px)`,
          });
        }

        if ($header.hasClass('scrolling-up')) {
          $header.css({
            top: st,
          });
          $sectionNav.css({
            '-webkit-transform': `translateY(${st - alertHeight}px)`,
            transform: `translateY(${st - alertHeight}px)`,
          });
        }
      }
    }

    function closeSection($element, onAnimationEnd = () => {}) {
      $element.removeClass(EXPANDED_CLASS).attr('aria-expanded', 'false');
      $element
        .next(arg.dropdown)
        .slideUp(300, onAnimationEnd)
        .attr('aria-hidden', 'true');
      if ($element.closest('.section__nav').length) {
        $body.removeClass('open-nav').css({
          '-webkit-transform': '',
          transform: '',
        });
        $('.section__nav').css({
          '-webkit-transform': '',
          transform: '',
        });
        $('.header').css({
          top: `${alertHeight}px`,
        });
        $win.scrollTop(st);
      }
    }

    function toggleSection($element) {
      if (!$element.hasClass(EXPANDED_CLASS)) {
        openSection($element);
      } else {
        closeSection($element);
      }
    }

    const expandDrop = function(e) {
      const $this = $(e.currentTarget);
      const $openedButton = $(arg.btnOpen).filter(`.${EXPANDED_CLASS}`);
      if (
        (e.which === 13 || e.type === 'click') &&
        $this.hasClass(EXPANDED_CLASS)
      ) {
        e.preventDefault();
        closeSection($this);
        return;
      }

      if ((e.which === 13 || e.type === 'click') && !$openedButton.length) {
        toggleSection($this);
      } else {
        closeSection($(arg.btnOpen).filter(`.${EXPANDED_CLASS}`), () => {
          toggleSection($this);
        });
      }
    };

    $(arg.btnOpen).on('click', $.throttle(1000, expandDrop));

    $lastElement.on('keydown', e => {
      const code = e.keyCode ? e.keyCode : e.which;
      if (e.shiftKey && Number(code) === 9) return;
      if (code === 9) {
        closeSection($(arg.btnOpen));
        $('.breadcrumb')
          .find('li:first-child a')
          .focus();
        e.preventDefault();
      }
    });

    $(document).on('touchstart click', e => {
      if (
        !$(e.target).hasClass('ui-datepicker-prev') &&
        !$(e.target).hasClass('ui-datepicker-next')
      ) {
        if (
          !$(arg.sectionTarget).is(e.target) &&
          !$(arg.sectionTarget).has(e.target).length
        ) {
          if ($(arg.btnOpen).hasClass(EXPANDED_CLASS)) {
            toggleSection($(arg.btnOpen));
          }
        }
      }
    });
  }

  return {
    init,
  };
})();

export default openClose;
